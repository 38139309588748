exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js-content-file-path-posts-about-index-mdx": () => import("./../../../src/templates/about.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/about/index.mdx" /* webpackChunkName: "component---src-templates-about-js-content-file-path-posts-about-index-mdx" */),
  "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-beauty-salon-01-index-mdx": () => import("./../../../src/templates/pc/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/beauty-salon-01/index.mdx" /* webpackChunkName: "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-beauty-salon-01-index-mdx" */),
  "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-building-material-02-index-mdx": () => import("./../../../src/templates/pc/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/building-material-02/index.mdx" /* webpackChunkName: "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-building-material-02-index-mdx" */),
  "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-consulting-01-index-mdx": () => import("./../../../src/templates/pc/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/consulting-01/index.mdx" /* webpackChunkName: "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-consulting-01-index-mdx" */),
  "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-portfolio-01-index-mdx": () => import("./../../../src/templates/pc/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/portfolio-01/index.mdx" /* webpackChunkName: "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-portfolio-01-index-mdx" */),
  "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-sports-fanblog-index-mdx": () => import("./../../../src/templates/pc/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/sports-fanblog/index.mdx" /* webpackChunkName: "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-sports-fanblog-index-mdx" */),
  "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-wp-sample-newsportal-index-mdx": () => import("./../../../src/templates/pc/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/wp-sample-newsportal/index.mdx" /* webpackChunkName: "component---src-templates-pc-mdx-fields-slug-js-content-file-path-posts-wp-sample-newsportal-index-mdx" */),
  "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-beauty-salon-01-index-mdx": () => import("./../../../src/templates/sp/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/beauty-salon-01/index.mdx" /* webpackChunkName: "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-beauty-salon-01-index-mdx" */),
  "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-building-material-02-index-mdx": () => import("./../../../src/templates/sp/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/building-material-02/index.mdx" /* webpackChunkName: "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-building-material-02-index-mdx" */),
  "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-consulting-01-index-mdx": () => import("./../../../src/templates/sp/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/consulting-01/index.mdx" /* webpackChunkName: "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-consulting-01-index-mdx" */),
  "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-portfolio-01-index-mdx": () => import("./../../../src/templates/sp/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/portfolio-01/index.mdx" /* webpackChunkName: "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-portfolio-01-index-mdx" */),
  "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-sports-fanblog-index-mdx": () => import("./../../../src/templates/sp/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/sports-fanblog/index.mdx" /* webpackChunkName: "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-sports-fanblog-index-mdx" */),
  "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-wp-sample-newsportal-index-mdx": () => import("./../../../src/templates/sp/{mdx.fields__slug}.js?__contentFilePath=/Users/osawa/Documents/blogs/portfolio/kzak-practices/posts/wp-sample-newsportal/index.mdx" /* webpackChunkName: "component---src-templates-sp-mdx-fields-slug-js-content-file-path-posts-wp-sample-newsportal-index-mdx" */)
}

